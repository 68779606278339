"use client";
import React, { useEffect, useState } from "react";

const Arrow = ({ isUp, onClick }: { isUp: boolean; onClick: () => void }) => {
  const [isRotated, toggleIsRotated] = useState(isUp);

  useEffect(() => {
    toggleIsRotated(isUp);
  }, [isUp]);

  return (
    <svg
      onClick={onClick}
      className={`cursor-pointer arrow ${isRotated ? "rotated" : ""}`}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Arrow;
