import { useCustomTokens } from "@sushiswap/hooks";
import { PublicWagmiConfig } from "@sushiswap/wagmi-config";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getToken as getTokenWeb3 } from "@wagmi/core/actions";
import { useCallback } from "react";
import { ChainId } from "sushi/chain";
import { Token } from "sushi/currency";
import { Address, isAddress } from "viem";
import { useConfig } from "wagmi";

interface UseTokenParams<T extends boolean> {
  chainId: ChainId | undefined;
  address: string | undefined | null;
  withStatus?: T;
  enabled?: boolean;
  keepPreviousData?: boolean;
}

type UseTokenReturn<T> = T extends true
  ? { token: Token; status: "UNKNOWN" | "APPROVED" | "DISAPPROVED" }
  : Token;

type Data = {
  id: string;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  image: string;
  incetivizationPoints?: number;
  status: "UNKNOWN" | "APPROVED" | "DISAPPROVED";
};

export const getTokenWithQueryCacheHydrate = <T extends boolean>(
  chainId: ChainId | undefined,
  data: Data,
  withStatus: T | undefined
): UseTokenReturn<T> | undefined => {
  if (data && chainId) {
    const { address, name, symbol, decimals, image, incetivizationPoints } =
      data;

    const token = new Token({
      chainId,
      name,
      decimals,
      symbol,
      address,
      image,
      incetivizationPoints,
    });

    if (withStatus) {
      return {
        token,
        status: data.status,
      } as UseTokenReturn<T>;
    }

    return token as UseTokenReturn<T>;
  }

  return undefined;
};

interface GetTokenWithQueryCacheFn {
  chainId: ChainId | undefined;
  address: string | undefined | null;
  customTokens: Record<string, Token>;
  hasToken: (cur: string | Token) => boolean;
  config: PublicWagmiConfig;
}

export const getTokenWithCacheQueryFn = async ({
  chainId,
  address,
  customTokens,
  hasToken,
  config,
}: GetTokenWithQueryCacheFn) => {
  // Try fetching from localStorage
  if (chainId && hasToken(`${chainId}:${address}`)) {
    const {
      address: tokenAddress,
      name,
      symbol,
      decimals,
      id,
      image,
    } = customTokens[`${chainId}:${address}`];
    return {
      address: tokenAddress,
      name,
      symbol,
      decimals,
      status: "UNKNOWN",
      id,
      image,
    } as Data;
  }

  // Fetch single token details
  if (chainId) {
    try {
      const tokenResponse = await fetch(`/api/token/${chainId}:${address}`);
      const { token, error } = await tokenResponse.json();

      if (!token) {
        throw Error(error);
      }
      const {
        decimals,
        address: tokenAddress,
        symbol,
        name,
        image,
        incetivizationPoints,
      } = token;
      return {
        address: tokenAddress,
        name,
        symbol,
        decimals,
        status: "APPROVED", // When coming from DB, we assume it's approved
        id: `${chainId}:${tokenAddress}`,
        image,
        incetivizationPoints,
      } as Data;
    } catch (e) {
      const resp = await getTokenWeb3(config, {
        address: address as Address,
        chainId,
      });
      const { decimals, address: tokenAddress, symbol, name } = resp;

      return {
        address: tokenAddress,
        name,
        symbol,
        decimals,
        status: "UNKNOWN",
        id: `${chainId}:${tokenAddress}`,
      } as Data;
    }
  } else {
    throw Error("Could not fetch token");
  }
};

export const useTokenWithCache = <T extends boolean = false>({
  chainId,
  address,
  withStatus,
  enabled = true,
  keepPreviousData: isKeepPreviousData = true,
}: UseTokenParams<T>) => {
  const { data: customTokens, hasToken } = useCustomTokens();
  const select = useCallback(
    (data: Data) => getTokenWithQueryCacheHydrate<T>(chainId, data, withStatus),
    [chainId, withStatus]
  );

  const config = useConfig();

  return useQuery({
    queryKey: ["token", { chainId, address }],
    queryFn: async () =>
      getTokenWithCacheQueryFn({
        chainId,
        address,
        customTokens,
        hasToken,
        config,
      }),
    enabled: Boolean(enabled && chainId && address && isAddress(address)),
    select,
    placeholderData: isKeepPreviousData ? keepPreviousData : undefined,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 900000, // 15 mins
    gcTime: 86400000, // 24hs
  });
};
