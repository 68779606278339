/* eslint-disable @next/next/no-img-element */
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";

export type ProfileImageSize = "xs" | "sm" | "md" | "lg";

const levelVariants = {
  xs: "text-xs",
  sm: "text-sm",
  md: "text-base",
  lg: "text-lg",
};

const containerVariants = {
  xs: "w-[38px] h-[38px]",
  sm: "w-[63px] h-[63px]",
  md: "w-[78px] h-[78px]",
  lg: "w-[206px] h-[206px]",
};

const UserProfileImage = ({
  size,
  imageUrl,
  level,
  className,
}: {
  size: ProfileImageSize;
  imageUrl: string | null | undefined;
  level?: number;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        `relative flex items-center justify-center ${containerVariants[size]}`,
        className
      )}
    >
      <img
        src="/pfpframe.png"
        alt="Profile Frame"
        className="absolute z-30 w-full h-full"
      />
      {imageUrl && (
        <img
          src={imageUrl}
          alt="Profile Image"
          className={`hexagon absolute z-20 w-[83%] h-[83%] left-[6%]`}
        />
      )}
      {!imageUrl && (
        <div className="hexagon absolute z-20 w-[83%] h-[83%] left-[6%] bg-black flex items-center justify-center overflow-hidden">
          <img
            src={"/nopfp.svg"}
            alt="Profile Image"
            className={`!h-[70%] !w-[70%]`}
          />
        </div>
      )}

      {!!level && (
        <div
          className={`absolute z-40 bottom-[5%] right-[17%] ${levelVariants[size]}`}
        >
          <LevelBadge level={level} size={size} />
        </div>
      )}
    </div>
  );
};

export default UserProfileImage;

const levelSizeVariants = {
  xs: "w-[15px] h-[15px] text-[8px] border-[1px]",
  sm: "w-[20px] h-[20px] text-[8px] border-[2px]",
  md: "w-[26px] h-[26px] text-[12px] border-[3px]",
  lg: "w-[48px] h-[48px] text-[22px] border-[4px]",
};

const LevelBadge = ({
  size,
  level,
}: {
  size: ProfileImageSize;
  level: number;
}) => {
  return (
    <div
      className={`z-40 border-bhDark bg-bhPink rounded-full flex items-center justify-center text-white font-bold ${levelSizeVariants[size]}`}
    >
      <p>{level}</p>
    </div>
  );
};
