"use client";

import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import * as serverActions from "src/actions";
import { useAccount, useSignMessage } from "wagmi";
import { isAddressEqual } from "viem";
import { PointsType, User } from "@prisma/client";
import { useRouter } from "next/navigation";
import { useLocalStorage } from "@sushiswap/hooks";

interface UserDetails {
  username: string | null;
  totalPoints: number;
  address: `0x${string}` | null;
  profileImageUrl?: string | null;
  referrals: Pick<User, "profileImageUrl" | "username" | "createdAt">[];
  points: UserPoints[];
  referredByUsername?: string | null;
  banned?: boolean;
}

interface UserContextType extends UserDetails {
  tryToRefreshProfile: () => Promise<void>;
  logout: () => Promise<void>;
  tryToLogin: () => Promise<void>;
  loginLoading: boolean;
  editProfile: (params: {
    username: string;
    profileImageUrl?: string;
    isProfileImageDelete?: boolean;
  }) => Promise<void>;
  skipRegisterMap: Record<string, boolean>;
  setSkipRegisterMap: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  initializing: boolean;
}

export interface UserPoints {
  pointsAssigned: number;
  id: number;
  assignedAt: Date | null;
  type: PointsType;
}

const UserContext = createContext<UserContextType>({
  username: null,
  address: null,
  tryToRefreshProfile: async () => {},
  tryToLogin: async () => {},
  logout: async () => {},
  referrals: [],
  totalPoints: 0,
  points: [],
  loginLoading: false,
  editProfile: async () => {},
  skipRegisterMap: {},
  setSkipRegisterMap: () => {},
  initializing: true,
  banned: false,
});

export function UserProvider({ children }: { children: ReactNode }) {
  const [loginLoading, setLoginLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const { signMessage } = useSignMessage();
  const router = useRouter();
  const [userDetails, setUserDetails] = useState<UserDetails>({
    username: null,
    points: [],
    address: null,
    totalPoints: 0,
    referrals: [],
    banned: false,
  });

  const { address: connectedWalletAddress } = useAccount();

  const [skipRegisterMap, setSkipRegisterMap] = useLocalStorage<
    Record<string, boolean>
  >("blackhole.didSkipRegister", {});

  const updateUserDetails = useCallback((details: Partial<UserDetails>) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      ...details,
    }));
  }, []);

  const logout = useCallback(async () => {
    await serverActions.logout();
    updateUserDetails({
      username: null,
      points: [],
      profileImageUrl: null,
      referrals: [],
      address: null,
      banned: false,
    });
  }, [updateUserDetails]);

  const editProfile = useCallback(
    async ({
      username,
      profileImageUrl,
      isProfileImageDelete,
    }: {
      username: string;
      profileImageUrl?: string;
      isProfileImageDelete?: boolean;
    }) => {
      const { user, error } = await serverActions.editProfile({
        username,
        profileImageUrl,
        isProfileImageDelete,
      });
      if (error || !user) {
        throw new Error(error || "Failed to edit profile");
      }
      updateUserDetails({
        username: user.username,
        profileImageUrl: user.profileImageUrl,
      });
    },
    [updateUserDetails]
  );

  const tryToLogin = useCallback(async () => {
    if (!connectedWalletAddress) {
      return;
    }

    setLoginLoading(true);

    const { challenge, error } = await serverActions.getOrCreateChallenge(
      connectedWalletAddress.toLocaleLowerCase()
    );

    if (error || !challenge) {
      setLoginLoading(false);
      // Create error dialog
      return;
    }

    signMessage(
      { message: challenge.uuid },
      {
        onSuccess: async (signature) => {
          try {
            const loginError = await serverActions.login({
              address:
                connectedWalletAddress.toLocaleLowerCase() as `0x${string}`,
              signature,
            });
            if (loginError) {
              // Assume user does not exist yet - prompt them to register
              router.push("/register");
              setLoginLoading(false);
              return;
            }

            const {
              user,
              error: getProfileError,
              referrals,
            } = await serverActions.getMyProfile();

            if (getProfileError || !user) {
              throw new Error(getProfileError || "Failed to get profile");
            }

            updateUserDetails({
              username: user.username,
              address: user.address as `0x${string}`,
              profileImageUrl: user.profileImageUrl,
              referrals,
              points: user.points,
              referredByUsername: user.referredByUsername,
              totalPoints: (user.points || []).reduce(
                (acc, val) => acc + val.pointsAssigned,
                0
              ),
              banned: user.banned,
            });

            setLoginLoading(false);
          } catch (e) {
            console.log(error);
            setLoginLoading(false);
            // Create error dialog
          }
        },
        onError: (error) => {
          console.log(error);
          setLoginLoading(false);
        },
      }
    );
  }, [connectedWalletAddress, router, signMessage, updateUserDetails]);

  const tryToRefreshProfile = useCallback(async () => {
    // This user has clicked "skip" on the register page
    // Don't attempt to login
    if (connectedWalletAddress && skipRegisterMap[connectedWalletAddress]) {
      return;
    }

    const { user, referrals } = await serverActions.getMyProfile();
    if (
      !user ||
      !connectedWalletAddress ||
      !isAddressEqual(user.address as `0x${string}`, connectedWalletAddress)
    ) {
      await logout();

      await tryToLogin();
      return;
    }

    updateUserDetails({
      username: user.username,
      address: user.address as `0x${string}`,
      profileImageUrl: user.profileImageUrl,
      referrals,
      referredByUsername: user.referredByUsername,
      totalPoints: (user.points || []).reduce(
        (acc, val) => acc + val.pointsAssigned,
        0
      ),
      banned: user.banned,
    });
  }, [
    connectedWalletAddress,
    updateUserDetails,
    logout,
    skipRegisterMap,
    tryToLogin,
  ]);

  // Silently try to login on load
  // If they have a cookie set - it will fetch their profile
  // If their profile address matches their connected address - they will be logged in
  useEffect(() => {
    if (connectedWalletAddress) {
      tryToRefreshProfile();
    }
  }, [tryToRefreshProfile, connectedWalletAddress]);

  useEffect(() => {
    // Short initialization period to allow for wallet auto connection and profile refresh
    // Without this - there is some glitching on initial load
    const timeout = setTimeout(() => {
      setInitializing(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...userDetails,
        tryToRefreshProfile,
        tryToLogin,
        logout,
        loginLoading,
        editProfile,
        skipRegisterMap,
        setSkipRegisterMap,
        initializing,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
