"use client";
import { ClipboardController } from "@sushiswap/ui";
import React from "react";
import CopyIcon from "../icons/copy";

const fontVariants = {
  small: "text-[10px]",
  medium: "text-[12px]",
  large: "text-[15px]",
};

const paddingVariants = {
  small: "p-1",
  medium: "p-[9px]",
  large: "p-[11px]",
};

const ReferralLink = ({
  link,
  profilePage,
  copyIconSize = "small",
  fontSize = "small",
  padding = "small",
}: {
  link: string;
  profilePage?: boolean;
  copyIconSize?: "small" | "large";
  fontSize?: "small" | "medium" | "large";
  padding?: "small" | "medium" | "large";
}) => {
  return (
    <div
      className={`border-[1px] border-white/20 flex items-center rounded-md justify-between ${
        paddingVariants[padding]
      } ${profilePage ? "bg-[#222222]" : "bg-black"}`}
    >
      <p
        className={`text-white truncate w-[calc(100%-60px)] ${fontVariants[fontSize]} font-medium`}
      >
        {link}
      </p>
      <ClipboardController>
        {({ setCopied, isCopied }) => (
          <div
            onClick={() => setCopied(link)}
            className="flex items-center gap-1 cursor-pointer"
          >
            <p className="text-[10px] text-white/40 font-medium">copy</p>
            <CopyIcon size={copyIconSize} />
          </div>
        )}
      </ClipboardController>
    </div>
  );
};

export default ReferralLink;
