import React from "react";

const Coins = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="#00FFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0898 10.3701C19.0352 10.7225 19.8763 11.3076 20.5356 12.0713C21.1949 12.835 21.6509 13.7526 21.8616 14.7392C22.0723 15.7258 22.0307 16.7496 21.7409 17.716C21.451 18.6823 20.9222 19.5599 20.2033 20.2677C19.4844 20.9755 18.5986 21.4906 17.6278 21.7653C16.6571 22.0401 15.6327 22.0656 14.6495 21.8396C13.6663 21.6135 12.756 21.1432 12.0027 20.4721C11.2494 19.801 10.6775 18.9508 10.3398 18.0001"
        stroke="#00FFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6H8V10"
        stroke="#00FFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7098 13.8799L17.4098 14.5899L14.5898 17.4099"
        stroke="#00FFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Coins;
