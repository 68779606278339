export default function CopyIcon({
  size = "small",
}: {
  size?: "small" | "large";
}) {
  if (size === "small") {
    return (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.16797 4.66797H3.33464C2.69122 4.66797 2.16797 5.19122 2.16797 5.83464V11.668C2.16797 12.3114 2.69122 12.8346 3.33464 12.8346H9.16797C9.81139 12.8346 10.3346 12.3114 10.3346 11.668V5.83464C10.3346 5.19122 9.81139 4.66797 9.16797 4.66797Z"
          fill="white"
        />
        <path
          d="M12.668 1.16797H6.83464C6.52522 1.16797 6.22847 1.29089 6.00968 1.50968C5.79089 1.72847 5.66797 2.02522 5.66797 2.33464V3.5013H10.3346C10.6441 3.5013 10.9408 3.62422 11.1596 3.84301C11.3784 4.0618 11.5013 4.35855 11.5013 4.66797V9.33463H12.668C12.9774 9.33463 13.2741 9.21172 13.4929 8.99293C13.7117 8.77413 13.8346 8.47739 13.8346 8.16797V2.33464C13.8346 2.02522 13.7117 1.72847 13.4929 1.50968C13.2741 1.29089 12.9774 1.16797 12.668 1.16797Z"
          fill="white"
        />
      </svg>
    );
  }

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 6H3C2.17275 6 1.5 6.67275 1.5 7.5V15C1.5 15.8273 2.17275 16.5 3 16.5H10.5C11.3273 16.5 12 15.8273 12 15V7.5C12 6.67275 11.3273 6 10.5 6Z"
        fill="white"
      />
      <path
        d="M15 1.5H7.5C7.10218 1.5 6.72064 1.65804 6.43934 1.93934C6.15804 2.22064 6 2.60218 6 3V4.5H12C12.3978 4.5 12.7794 4.65804 13.0607 4.93934C13.342 5.22064 13.5 5.60218 13.5 6V12H15C15.3978 12 15.7794 11.842 16.0607 11.5607C16.342 11.2794 16.5 10.8978 16.5 10.5V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5Z"
        fill="white"
      />
    </svg>
  );
}
